<template>
  <el-dialog title="请假申请" width="500px" :visible.sync="selfShow" :close-on-click-modal="false">
    <div>
      <e-form
        ref="refForm"
        :config="formConfig"
        :column="formColumn"
        :data.sync="selfData"
        :rules="rules"
      />
    </div>
    <div slot="footer">
      <el-button size="small" @click="popupCancel">取消</el-button>
      <el-button size="small" type="primary" @click="popupConfirm">提交审核</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    accountId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      selfShow: false,
      selfData: {
        account_id: '',
        qing_jia_type: '',
        tiao_xiu_date_list: [],
        message: ''
      },
      formConfig: {
        inline: false,
        width: '100%'
      },
      formColumn: [
        {
          type: 'select',
          title: '请假类型',
          field: 'qing_jia_type',
          required: true,
          options: [
            {
              key: '调休',
              val: '调休'
            },
            {
              key: '事假',
              val: '事假'
            },
            {
              key: '病假',
              val: '病假'
            },
            {
              key: '年假',
              val: '年假'
            },
            {
              key: '婚假',
              val: '婚假'
            },
            {
              key: '产假',
              val: '产假'
            }
          ]
        },
        {
          type: 'datetimerange',
          title: '请假时间',
          field: 'tiao_xiu_date_list',
          required: true,
          format: 'yyyy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss'
        },
        {
          type: 'text',
          title: '请假事由',
          field: 'message',
          required: true
        }
      ],
      rules: {
        qing_jia_type: [{ required: true, message: '请选择请假类型' }],
        tiao_xiu_date_list: [{ required: true, message: '请选择请假时间' }],
        message: [{ required: true, message: '请选择请假时间' }]
      }
    }
  },
  watch: {
    selfShow(val) {
      this.$emit('update:show', val)
    }
  },
  mounted() {
    this.selfShow = this.show
  },
  methods: {
    // 弹窗取消
    popupCancel() {
      this.selfShow = false
      this.$emit('update:show', false)
    },
    // 弹窗确认
    popupConfirm() {
      if (!this.selfData.account_id) this.selfData.account_id = this.$store.state.loginAccount.id
      this.$refs.refForm.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api({
            method: 'post',
            url: '/admin/gcvip/dingding/saveOrUpdate',
            data: this.selfData
          })
            .then((res) => {
              if (res.data.state === 'ok') {
                this.popupCancel()
                this.$emit('success')
                this.$message.success('操作成功')
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              this.$message.error(error)
            })
        }
      })
    }
  }
}
</script>

<style></style>
